import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default ({src, threshold = 0, style, render}) => {
  const [visible, setVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView, entry } = useInView({
    threshold: threshold,
    rootMargin: '500px 0px 0px 0px',
  });

  useEffect(() => {
    if (inView && !visible) {
        setVisible(true);
    }
  }, [inView])

  if (render) {
    return <img ref={ref}
      src={src}
      style={style}
/*       onLoad={() => setLoaded(true)}
      style={style} */
    />
  }

  return (
    <span 
      className={`anim-img-ctnr ${visible ? 'visible' : ''}`}
    >
        <img ref={ref}
        src={visible ? src : null}
        data-src={visible ? null : src}
        className={`anim-img ${loaded ? 'loaded' : ''}`}
        onLoad={() => setLoaded(true)}
        style={style}
        />
    </span>
  );
}